<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="Event List" slot="title" link="/helpContent/holidayList" />
    <ValidationObserver ref="validator">
      <FormRow>
          <div class="col-sm-2">
            <Year label="Year" v-model="filtering.year" />
          </div>
          <div class="col-sm-2">
            <InputMonth :width="150" label="Month" v-model="filtering.month" />
          </div>
          <!-- <div class="col-sm-2">
            <FormCheckbox v-model="filtering.isHeader">Is Header Event</FormCheckbox>
          </div> -->
          <div class="col-sm-2">
    
    <div>
      <input type="radio" id="1" value="1"  v-model="filtering.isHeader" />
<label for="1">  Is Header Event?</label>


    </div>

    </div>
    <div class="col-sm-2">

    <div>


<input type="radio" id="2" value="2"  v-model="filtering.isHeader" />
<label for="2">  Is Company Event?</label>
    </div>

    </div>
          <div class="col-sm-2">
              <FormButton style="margin-top: 5px" type="primary" @click="search" :rounded="true">
                  <Icon type="ios-search" />
              </FormButton>
          </div>
      </FormRow>
    </ValidationObserver>
    <DataTable
      :actions="true"
      :data="eventList"
      :columns="tableConfig"
      :is-loading="isLoading"
      :pagination="pagination"
      @on-selection-change="selectChange"
      @on-page-change="pageChange"
      @on-sort-change="sortChange">
      <template slot="actionsLeft">
        <TableButton @click="() => add()">
          <img :src="require(`Assets/images/plus-1.png`)"  />
          Add
        </TableButton>
        <TableButton @click="() => edit()">
            <img :src="require(`Assets/images/edit-1.png`)"/>
          Edit
        </TableButton>
        <TableButton @click="() => remove()">
          <img :src="require(`Assets/images/del.png`)"  />
          Remove
        </TableButton>
      </template>
    </DataTable>
    <Popup
      title="Information"
      :value="warningModal.visible"
      @close="() => showWarningModal(false)"
    >
      {{ warningModal.message }}
      <div slot="footer">
        <FormButton
          v-if="warningModal.buttons.cancel"
          @click="() => showWarningModal(false)"
          >Cancel
        </FormButton>
        <FormButton
          v-if="warningModal.buttons.confirm"
          type="success"
          @click="confirmDelete"
          >Confirm
        </FormButton>
        <FormButton
          v-if="warningModal.buttons.ok"
          @click="() => showWarningModal(false)"
          >Ok</FormButton
        >
      </div>
    </Popup>
    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
    </Popup>
  </FormWrapper>
</template>

<script>
  import FormWrapper from "Components/form/FormWrapper";
  import PageTitle from "Components/layout/PageTitle";
  import DataTable from "Components/DataTable";
  import FormCheckbox from 'Components/form/FormCheckbox';
  import loadingMixin from "Mixins/loadingMixin";
  import { getEventList, deleteEvent } from "../api";
  import tableConfig from "./table";
  import validateSelectedRows from "Mixins/validateSelectedRows";
  import { ValidationObserver } from "vee-validate";
  import Year from "../../../components/Year";
  export default {
    name: "List",
    mixins: [loadingMixin, validateSelectedRows],
    components: {
      FormWrapper,
      // FormCheckbox,
      DataTable,
      PageTitle,
      ValidationObserver,
      Year,
    },
    data() {
      return {
        filtering: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          isHeader: 0,
        },
        sorting: {
          key: "id",
          order: "asc",
        },
        pagination: {
          perPage: 10,
          page: 1,
          total: 0,
        },
        tableConfig,
        eventList: [],
        selectedRows: [],
        showMessage: {
          isVisible: false,
          message: "",
        },
        buttonToShow: "",
      };
    },
    computed: {
      userData() {
        return this.$store.state.user.user;
      },
    },
    created() {
      this.getDataByFilter();
    },
    methods: {
      add() {
        this.$router.push({ path: "/addUpdateEvent" });
      },
      edit(){
          if (this.validateSelectedLength(1)) {
              this.$router.push({ path: `/addUpdateEvent/${btoa(this.selectedRows[0].Id)}/${btoa(this.selectedRows[0].IsHeaderEvent)}` });
          }
      },
      search() {
        this.pagination.page = 1;
        this.getDataByFilter();
      },
      selectChange(data) {
        this.selectedRows = data;
      },
      sortChange(data) {
        this.sorting.key = data.key;
        this.sorting.order = data.order;
        this.getDataByFilter();
      },
      remove() {
        if (this.validateSelectedLength(1)) {
          if (this.deleteSelectedValue()) {
          }
        }
      },
      confirmDelete() {
        this.showWarningModal(false);
        const data = {
          eventId: this.selectedRows[0].Id,
        };
        deleteEvent(data).then((res) => {
          if (res.data[0].status > 1) {
            this.showWarningModal(true);
            this.selectedRows=[];
            this.warningModal.buttons.ok =true;
            this.warningModal.buttons.cancel =false;
            this.warningModal.buttons.confirm =false;
            this.warningModal.message = res.data[0].message;
          } else {
            this.selectedRows=[];
            this.warningModal.buttons.ok =true;
            this.warningModal.buttons.cancel =false;
            this.warningModal.buttons.confirm =false;
            this.showMessage.isVisible = true;
            this.showMessage.message = res.data[0].message;
            this.getDataByFilter();
          }
        });
      },
      getDataByFilter() {
        setTimeout(() => {
          this.$refs.validator.validate().then((result) => {
            if (result) {
              this.showLoader();
              const { key, order } = this.sorting;
              const { perPage, page } = this.pagination;

              const filters = {};
              filters.year = this.filtering.year;
              filters.month = this.filtering.month;
              filters.isHeader = this.filtering.isHeader?this.filtering.isHeader:0;
              const data = {
                filterjson: {
                  filter: [filters],
                  sort: [{ key, order }],
                  paging: [
                    {
                      startIndex: page,
                      pagesize: perPage,
                    },
                  ],
                },
              };
              getEventList(data)
                .then(this.handleResponse)
                .catch(this.handleError);
            }
          });
        }, 50);
      },
      pageChange(page) {
        this.pagination.page = page;
        this.getDataByFilter();
      },
      handleResponse(response) {
        this.hideLoader();
        this.eventList = response.data;
        this.pagination.total = response.NumberOfRecords;
      },
    },
  };
</script>