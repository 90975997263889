<template>
    <div :class="$style.actions">
        <div >

            <template v-if="row.ImagePath">
                <!-- :href="row.ImagePath" -->
                <FormButton size="small" type="text"   @click="viewImage(row.ImagePath)" v-if="row.ImagePath" tag="a" left-icon="md-eye">View</FormButton>
            </template>
        </div>
    </div>
</template>

<script>
import config from "Config/base";
    export default {
        name: "ActionsCell",
        props: {
            row: {
                type: Object,
                required: true,
            }
        },
        computed:{
            userData() {
                return this.$store.state.user.user
            }
        },
        methods: {
            viewImage(imagePath){
                if (imagePath.split(",")[0].replace(/["']/g, "")) {
                     window.open(imagePath.split(",")[0].replace(/["']/g, ""),"_blank")
                }
            }
        }
    }
</script>

<style lang="scss" module>

    .actions {
        display: flex;
        align-items: center;
        justify-content: center;
        a,
        button {
            margin: 0 5px
        }
        div {
            margin: 0 5px;
            color: #55a7a0;
            display: inline-block;
            &:hover {
                color: #55a7a0;
            }
            &.error {
                color: #eac222;
                font-size: 19px;
                &:hover {
                    font-size: 19px;
                }
            }
        }
    }
</style>

