import ImagesActionsCell from './ImagesActionsCell';

export default [
    {
      type: "selection",
      width: 38,
      align: "center",
    },
    {
      title: "Event Name",
      key: "Event_Name",
      minWidth: 80,
      width: 250,
    },
    {
      title: "Event Details",
      key: "EventDetails",
      minWidth: 80,
      width: 350,
    },
    {
      title: "Event Date",
      key: 'Event_Date',
      minWidth: 80,
      sortable: true,
    },
    {
      title: "Date From",
      key: 'HeaderFrom',
      minWidth: 80,
      sortable: true,
    },
    {
      title: "Date To",
      key: 'HeaderTo',
      minWidth: 80,
      sortable: true,
    },
    {
      title: "Day",
      key: "Day",
      minWidth: 80,
    },
    {
      title: "Image",
      key: "",
      minWidth: 80,
      render: (h, params) => {
          return h('div', [
              h(ImagesActionsCell, {
                  props: {
                      row: params.row
                  }
              }),
          ]);
      }
    }
];
